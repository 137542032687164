@import "../../../Configs/config.scss";

.Category {
  all: unset;
  padding: 10px 25px;
  border-radius: $border-radius;
  background-color: $field-color;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.4rem;
  align-items: center;
  width: auto;
  margin-right: 10px;
  user-select: none;
  cursor: pointer;

  &__Image {
    width: 25px;
    user-select: none;
  }

  &__Text {
    width: 100%;
    font-size: 0.8rem;
  }
}

.Category-Active {
  all: unset;
  padding: 10px 25px;
  border-radius: $border-radius;
  background-color: $main-color;
  display: grid;
  color: #fff;
  grid-template-columns: auto 1fr;
  gap: 0.4rem;
  align-items: center;
  width: auto;
  margin-right: 10px;
  user-select: none;

  &__Image {
    width: 25px;
    user-select: none;
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }

  &__Text {
    color: #fff;
    width: 100%;
    font-size: 0.8rem;
  }
}
