.Hidden-Wrapper {
  padding: 0px 20px 20px 20px;
  overflow: hidden;
}

.Categories {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 20px;

  &__Headline {
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9rem;
  }
}

.Categories::-webkit-scrollbar {
  display: none;
}

.Products {
  display: grid;
  gap: 10px;
  margin-bottom: 40px;
}
