@import "../../Configs/config.scss";

.HeroSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 30px 20px;
  background-color: #dedede;
  height: auto;
  max-height: 180px;
  border-radius: $border-radius;
  overflow: hidden;
  user-select: none;

  &__Image {
    object-fit: contain;
    width: 100%;
    user-select: none;
  }
}
