@import "../../../Configs/config.scss";

.NewsEntry {
  all: unset;
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: $field-color;
  height: auto;
  width: 90%;
  min-width: 100%;
  margin-right: 20px;
  border-radius: $border-radius;
  overflow: hidden;
  user-select: none;
  position: relative;

  &__Image {
    width: 100px;
    object-fit: cover;
    height: 100%;
    user-select: none;
  }

  &__Data-Wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__Headline {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &__Text {
      font-size: 0.7rem;
    }
  }
}

.NewsEntry:last-of-type {
  margin-right: 0px;
}
