@import "../../Configs/config.scss";

.BackHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 20px 20px;

  &__logo {
    height: 40px;
  }

  &__InfoSection {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__InfoBadge {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 30px;
      background-color: $main-color;
      border-radius: 1rem;
      text-decoration: none;
      color: #fff;
      font-size: 0.8rem;
    }
  }
}
