.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 20px 20px;

  &__logo {
    height: 40px;
  }

  &__InfoSection {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__InfoBadge {
      border-radius: 50%;
      padding: 5px;
      background-color: #dedede;
      height: 30px;
      width: 30px;
      margin-left: 5px;

      &__Image {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
