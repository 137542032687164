@import "../Configs/config.scss";

.ImpressumPage {
  padding: 30px 0 0 0;

  &__Wrapper {
    padding: 0px 20px 40px 20px;

    &__Headline {
      margin-bottom: 20px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.9rem;
    }

    &__Paragraph {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    &__Span {
      font-weight: 700;
    }

    &__Link {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      text-decoration: none;
      color: $highlight-color;
    }

    &__AdminLink {
      all: unset;
      color: $highlight-color;
    }
  }
}
