// Farbe für alle Hintergründe (Auch der Hauptseite an sich)
$background-color: #fff;

// Farbe für alle Ebenen, die sich von der Hauptseite abheben sollen
$field-color: #dedede;

// Farbe für alle Ebenen, die aktiv dargestellt werden sollen (z.B. aktive Kategorie-Tabs)
$main-color: #484848;

// Farbe für Hervorhebungen wie Allergene
$highlight-color: #ffaf0a;

// Der Border-Radius
$border-radius: 1rem;
