@import "../../../Configs/config.scss";

.Product {
  padding: 1.8rem;
  border-radius: $border-radius;
  background-color: $field-color;
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  align-items: center;
  width: auto;
  user-select: none;

  &__Top-Wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    justify-content: space-between;

    &__HeadlineSize {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 0.5rem;
    }
  }

  &__Text {
    font-size: 0.9rem;

    &__Headline {
      font-size: 0.9rem;
      font-weight: 600;
    }

    &__Allergene {
      font-size: 0.5rem;
      padding: 0px 0.1rem;
      color: $highlight-color;
      text-decoration: none;
    }

    &__Description {
      font-size: 0.7rem;
    }
  }
}
