@import "../../Configs/config.scss";

.Footer {
  width: 100%;
  background-color: $main-color;
  padding: 2rem 20px;
  text-align: center;

  &__Paragraph {
    font-size: 0.8rem;
    color: #fff;
  }
}
